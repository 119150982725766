import React from "react";
import tw from "twin.macro";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from "../components/layout";


export default () => (
  <Layout>
    <h1>Contact Me</h1>
    <GatsbySeo title="Contact Richard"/>
    <hr tw="border my-2" />
    <ul>
      <li><a href="https://github.com/runchard">github</a></li>
    </ul>
  </Layout>
);
